import React, { createContext, useState, useContext, ReactNode } from 'react';

interface AuthState {
  accessToken: string;
  adminName: string;
  profilePic: string;
  isAuthenticated: boolean;
  setAuthData: (data: Partial<AuthState>) => void;
  clearAuthData: () => void;
}

const initialAuthState: AuthState = {
  accessToken: '',
  adminName: '',
  profilePic: '',
  isAuthenticated: false,
  setAuthData: () => {},
  clearAuthData: () => {},
};

const AuthContext = createContext<AuthState>(initialAuthState);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [authState, setAuthState] = useState(initialAuthState);

  const setAuthData = (data: Partial<AuthState>) => {
    setAuthState((prev) => ({
      ...prev,
      ...data,
      isAuthenticated: !!data.accessToken,
    }));
  };

  const clearAuthData = () => {
    setAuthState(initialAuthState);
    localStorage.clear();
  };

  return (
    <AuthContext.Provider value={{ ...authState, setAuthData, clearAuthData }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
