import Axios from "axios-observable";
import { Observable, Observer, from } from "rxjs";
import { BASE_URL } from "src/config";
import { BookType, SearchBooksformtype } from "src/typings/book"; 
import { handleAuthError } from "src/api/authUtils"

export const getBooks = (data: SearchBooksformtype, skip: number, limit: number, navigate: (path: string) => void) => {

  return Observable.create((observer: Observer<[]>) => {
    Axios.post<[]>(
      `${BASE_URL}/books/admin/get-paginated-filter-books?skip=${skip}&limit=${limit}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
    )
      .subscribe(
        (resp) => {
          observer.next(resp.data);
        },
        (error) => {
          handleAuthError(error, navigate);
          let errorMsg: string = "";
          let errorCode: number = 0;
          if (error.response) {
            errorCode = error.response.data.statusCode;
            errorMsg = error.response.data.message;
            observer.error({ errorMsg, errorCode });
          }
        }
      );
  });
};

export const updateBook = (data: BookType, EventId: string | object, navigate: (path: string) => void) => {

  return Observable.create((observer: Observer<any>) => {
    Axios.post<any>(`${BASE_URL}/books/admin/edit?id=${EventId}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }).subscribe(
      (resp) => {
        observer.next(resp.data);
      },
      (error) => {
        handleAuthError(error, navigate);
        let errorMsg: string = "";
        if (error.response) {
          errorMsg = error.response.data.message;
          observer.error({ errorMsg });
        }
      }
    );
  });
};

export const addBook = (data: any, navigate: (path: string) => void) => {
  
  return Observable.create((observer: Observer<any>) => {
    Axios.post<any>(
      `${BASE_URL}/books/admin/add`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
    )
      .subscribe(
        (resp) => {
          observer.next(resp.data);
        },
        (error) => {
          handleAuthError(error, navigate);
          let errorMsg: string = "";
          let errorCode: number = 0;
          if (error.response) {
            errorCode = error.response.data.statusCode;
            errorMsg = error.response.data.message;
            observer.error({ errorMsg, errorCode });
          }
        }
      );
  });
};

export const deleteBook = (bookId: string, navigate: (path: string) => void) => {
  
  return new Observable((observer) => {
    from(
      Axios.delete(`${BASE_URL}/books/admin/delete?id=${bookId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
    ).subscribe(
      (resp) => {
        observer.next(resp.data);
        observer.complete();
      },
      (error) => {
        handleAuthError(error, navigate);
        let errorMsg: string = "";
        if (error.response) {
          errorMsg = error.response.data.message;
          observer.error({ errorMsg });
        }
      }
    );
  });
};
