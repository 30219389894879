import Axios from "axios-observable";
import { Observable, Observer, from } from "rxjs";
import { BASE_URL } from "src/config";
import { BookType, OrderType, SearchBooksformtype } from "src/typings/book"; 
import { handleAuthError } from "src/api/authUtils"

export const getOrders = (data: SearchBooksformtype, skip: number, limit: number, navigate: (path: string) => void) => {

  return Observable.create((observer: Observer<[]>) => {
    Axios.post<[]>(
      `${BASE_URL}/event/fetch-orders-admin?skip=${skip}&limit=${limit}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          createdAt: localStorage.getItem('createdAt'),
          apikey: localStorage.getItem('apikey'),
        },
      }
    )
      .subscribe(
        (resp) => {
          observer.next(resp.data);
        },
        (error) => {
          handleAuthError(error, navigate);
          let errorMsg: string = "";
          let errorCode: number = 0;
          if (error.response) {
            errorCode = error.response.data.statusCode;
            errorMsg = error.response.data.message;
            observer.error({ errorMsg, errorCode });
          }
        }
      );
  });
};

export const updateOrder = (data: OrderType, OrderId: string | object, navigate: (path: string) => void) => {

  return Observable.create((observer: Observer<any>) => {
    Axios.post<any>(`${BASE_URL}/event/edit-order?id=${OrderId}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        createdAt: localStorage.getItem('createdAt'),
        apikey: localStorage.getItem('apikey'),
      },
    }).subscribe(
      (resp) => {
        observer.next(resp.data);
      },
      (error) => {
        handleAuthError(error, navigate);
        let errorMsg: string = "";
        if (error.response) {
          errorMsg = error.response.data.message;
          observer.error({ errorMsg });
        }
      }
    );
  });
};

export const fetchOrder = (data: any, OrderId: string | object, navigate: (path: string) => void) => {

  return Observable.create((observer: Observer<any>) => {
    Axios.post<[]>(`${BASE_URL}/event/fetch-order-info?orderId=${OrderId}`,       data,
      {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        createdAt: localStorage.getItem('createdAt'),
        apikey: localStorage.getItem('apikey'),
      },
    }).subscribe(
      (resp) => {
        observer.next(resp.data);
      },
      (error) => {
        handleAuthError(error, navigate);
        let errorMsg: string = "";
        if (error.response) {
          errorMsg = error.response.data.message;
          observer.error({ errorMsg });
        }
      }
    );
  });
};

