import Axios from 'axios-observable';
import { Observable, Observer } from 'rxjs';
import { BASE_URL } from 'src/config';
import { handleAuthError } from "src/api/authUtils"


export const uploadImage = (data: {}, navigate: (path: string) => void) => {
    console.log(data)
    return Observable.create((observer: Observer<any>) => {
      Axios.post<any>(
        `${BASE_URL}/aws-util/generate-presigned-url`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.accessToken}`,
          }
        },
  
      )
        .subscribe(
          (resp) => {
            // console.log("Response", resp)
            observer.next(resp.data);
          },
          (error) => {
            handleAuthError(error, navigate);
            let errorMsg: string = "";
            let errorCode: number = 0;
            if (error.request) {
              errorCode = error.response.data.statusCode;
              errorMsg = error.response.data.message;
              observer.error({ errorMsg, errorCode });
            }
          }
        )
    })
  }
  
  export const uploadToPresigned = (file: File ,url:string, navigate: (path: string) => void) => {
    return Observable.create((observer: Observer<any>) => {
      Axios.put<any>(
        `${url}`,
        file
      )
        .subscribe(
          (resp) => {
            // console.log("Response", resp)
            observer.next(resp.data);
          },
          (error) => {
            handleAuthError(error, navigate);
            console.log("Error", error)
           
          }
        )
    })
  }