import React, { useState } from 'react';
import { Grid, Card } from '@mui/material';
import { Button } from '@mui/material';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import MuiAlert, { AlertProps, AlertColor } from '@mui/material/Alert';
import { ValidateOTPDataResponseType } from 'src/typings/auth';
import { useNavigate } from 'react-router-dom';
import logo from 'src/images/greet-fydo.png';
import './login.css'
import Api from 'src/api';
import { useAuth } from 'src/auth/AuthContext';

export interface State extends SnackbarOrigin {
  open: boolean;
}

interface LoginFormType {
  mobile: string,
  otp: string,
}
const initialLoginFormValues: LoginFormType = {
  mobile: "",
  otp: "",
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Login = () => {
  let navigate = useNavigate();
  const { setAuthData } = useAuth();
  const [displayOtp, setdisplayOtp] = useState(false);
  const [Values, setValues] = useState(initialLoginFormValues);
  const [invalidOtpMessage, setinvalidOtpMessage] = useState("")
  const [invalidMessage, setinvalidMessage] = useState("")
  const [otpId, setOtpID] = useState("");
//  const [acessToken, setacessToken] = useState("");
  const [loading, enableLoading] = useState(false);

  const [snackbarState, setSnackbarState] = useState({
    msg: '',
    open: false,
    color: 'success'
  });

  const handleClose = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };



  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setValues({
      ...Values,
      [name]: value,
    })
  }

  const mobileNumberValidation = (event: React.FormEvent) => {
    event.preventDefault();
    if (Values.mobile === "") {
      setinvalidMessage("Enter Mobile Number")
      setdisplayOtp(false);
    }
    else if (isNaN(Number(Values.mobile))) {
      setinvalidMessage("Enter only numeric values")
      setdisplayOtp(false);

    }
    else if (Values.mobile.length != 10) {
      setinvalidMessage("Mobile number should contain 10 digits")
      setdisplayOtp(false);
    }
    else if (Values.mobile !== "9124046288") {
      setinvalidMessage("This mobile number is not allowed to log in");
      setdisplayOtp(false);
    }
    else {
      getOtp();
      setinvalidMessage("")
    }
    // setValues({
    //   ...Values,
    //   ["mobile"]: "",
    // })
  }


  const otpValidation = (event: React.FormEvent) => {
    event.preventDefault();
    if (Values.otp === "") {
      setinvalidOtpMessage("Enter otp");
    }
    else if (isNaN(Number(Values.otp))) {
      setinvalidOtpMessage("Enter only numeric values")
    }
    else if (Values.otp.length !== 6) {
      setinvalidOtpMessage("Enter 6 digit number")
    }
    else {
      validateOtp();
      setinvalidOtpMessage("")
    }
    setValues({
      ...Values,
      ["otp"]: "",
    })
  }
  const getOtp = () => {
    Api.auth.getOtp({ mobile: Values.mobile }).subscribe({
      next: (response: { otpId: string }) => {
        setOtpID(response.otpId);
        setSnackbarState({
          open: true,
          msg: 'OTP sent to the mobile number!',
          color: 'success',
        });
        setdisplayOtp(true);
      },
      error: (error: { errorMsg: string }) => {
        setSnackbarState({
          open: true,
          msg: error.errorMsg,
          color: 'error',
        });
        setdisplayOtp(false);
      },
    });
  };

  const validateOtp = () => {
    Api.auth.validateOtp({ otpId, otp: Values.otp }).subscribe({
      next: (response: ValidateOTPDataResponseType) => {
        setAuthData({
          accessToken: response.accessToken,
          adminName: response.name,
          profilePic: response.profilePicurl,
        });
  
        if (response.roles?.includes('CONTENT_ADMIN')) {
          localStorage.setItem('paymentAdmin', 'yes');
        } else {
          localStorage.setItem('paymentAdmin', 'no');
        }
  
        localStorage.setItem('accessToken', response.accessToken);
        localStorage.setItem('adminName', response.name);
        localStorage.setItem('profilePic', response.profilePicurl);
        localStorage.setItem('admin_id', response._id);
  
        setSnackbarState({
          open: true,
          msg: 'OTP successfully verified!',
          color: 'success',
        });
  
        enableLoading(true);
        setTimeout(() => {
          navigate('/dashboard/overview');
          enableLoading(false);
        }, 2000);
      },
      error: (error: { errorMsg: string }) => {
        setSnackbarState({
          open: true,
          msg: error.errorMsg,
          color: 'error',
        });
      },
    });
  };
  
  

//   const getOtp = () => {
//     Api.auth.getOtp(
//       {
//         mobile: Values.mobile,
//         // role: 'ADMIN',
//       }
//     ).subscribe(
//       (response: { otpId: string }) => {
//         setOtpID(response.otpId);
//         setSnackbarState({
//           open: true,
//           msg: 'OTP sent to the mobile number!',
//           color: 'success',
//         });
//         setdisplayOtp(true);
//       },
//       (error: { errorMsg: string }) => {
//         setSnackbarState({
//           open: true,
//           msg: error.errorMsg,
//           color: 'error',
//         });
//         setdisplayOtp(false);
//       }
//     )
//   }


//   const validateOtp = () => {
//     Api.auth.validateOtp(
//       {
//         otpId: otpId,
//         otp: Values.otp,
//         // role: 'ADMIN',
//       }
//     ).subscribe(
//       (response: ValidateOTPDataResponseType) => {
//         setAuthData({
//           accessToken: response.accessToken,
//           adminName: response.name,
//           profilePic: response.profilePicurl,
//         });
//         setSnackbarState({
//           open: true,
//           msg: 'OTP successfully verified!',
//           color: 'success',
//         });
        
//         if (response.roles.includes('CONTENT_ADMIN')) {
//           localStorage.setItem('paymentAdmin', "yes")
//         }
//         else {
//           localStorage.setItem('paymentAdmin', "no")
//         }
//         localStorage.setItem('accessToken', response.accessToken)
//         localStorage.setItem('adminName', response.name)
//         localStorage.setItem('profilePic', response.profilePicurl)
//         localStorage.setItem('admin_id', response._id)
// //        setacessToken(response.accessToken)
//         console.log(response.name)
//         enableLoading(true);
//         setTimeout(() => {
//           navigate('/dashboard/overview');
//           enableLoading(false);
//         }, 2000);
//       },
//       (error: { errorMsg: string }) => {
//         setSnackbarState({
//           open: true,
//           msg: error.errorMsg,
//           color: 'error',
//         });
//       }
//     )
//   }

  return (
    <div className="login_page flex">
      <Snackbar
        open={snackbarState.open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={snackbarState.color as AlertColor} sx={{ width: '100%' }}>
          {snackbarState.msg}
        </Alert>

      </Snackbar>

      <Card className="login_card flex">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        // xs={12}
        >

          {/* <Grid item className="login_image flex" lg={6}>
            <img src="https://image.freepik.com/free-vector/business-team-discussing-ideas-startup_74855-4380.jpg" alt="" />
          </Grid> */}
          <Grid item className="login_form" lg={6}>
            <div className="flex">
              <div className="flex" style={{ margin: '10px' }}>
                <img src={logo} alt="Lfyd" width="180px" height="80px" />
              </div>
              <h2 className='-mb-4'>Please fill the details to get yourself verified.</h2>
              {displayOtp ? <form action="" method="get" className="flex" >
                <input
                  type="text"
                  placeholder="Enter OTP"
                  name="otp" value={Values.otp}
                  onChange={handleInputChange}
                />
                <span className="invalidmsg">{invalidOtpMessage}</span>

                <button
                  id='login-btn'
                  onClick={(e) => { otpValidation(e) }}
                >
                  {loading ? <CircularProgress style={{ color: 'white' }} /> : 'Login'}
                </button>

              </form> :
                <form className="flex" >
                  <input
                    type="text"
                    placeholder="Enter mobile number"
                    name="mobile"
                    value={Values.mobile}
                    onChange={handleInputChange}
                  />
                  <span className="invalidmsg">{invalidMessage}</span>
                  {loading}
                  <button
                    id="coninue-btn"
                    onClick={(event) => { mobileNumberValidation(event) }}>
                    {loading ? <CircularProgress style={{ color: 'white' }} /> : 'Continue'}
                  </button>
                </form>
              }

            </div>
          </Grid>
        </Grid>
      </Card>

    </div>
  )
}

export default Login
