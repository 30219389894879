import Axios from 'axios-observable';
import { Observable, Observer } from 'rxjs';
import { BASE_URL } from 'src/config';
import {
  SendOTPDataType,
  ValidateOTPDataType,
  SendOTPResponseType,
  ValidateOTPDataResponseType,
} from 'src/typings/auth';
import { URL_IV, URL_KEY } from "../../src/config";
const CryptoJS = require("crypto-js");

//const createdAt = String(new Date().valueOf());
const createdAt = String(new Date().getTime());
const password = `${URL_KEY}${createdAt}`;
const encryptedApiKey = CryptoJS.AES.encrypt(password, URL_KEY, {
  iv: URL_IV,
}).toString();

const defaultHeaders = {
  accessKey: process.env.REACT_APP_ACCESS_KEY,
  createdAt,
  apikey: CryptoJS.AES.encrypt(password, URL_KEY, {
    iv: URL_IV,
  }).toString(),
};
localStorage.setItem("createdAt", createdAt);
localStorage.setItem("apikey", encryptedApiKey);


const handleError = (error: any, observer: Observer<any>) => {
  let errorMsg = 'An unexpected error occurred. Please try again later.';
  if (error.response) {
    const statusCode = error.response.data.statusCode;
    errorMsg = error.response.data.message || errorMsg;
    if (statusCode === 404 || statusCode === 403 || statusCode === 500) {
      errorMsg = error.response.data.message;
    }
  } else if (!error.request) {
    errorMsg = 'Shoot! Well, this is unexpected. Our team is looking into it.';
  }
  observer.error({ errorMsg });
};

export const getOtp = (data: SendOTPDataType) => {
  return new Observable((observer: Observer<SendOTPResponseType>) => {
    Axios.post<SendOTPResponseType>(
      `${BASE_URL}/organiser/send-otp-for-auth`,
      data,
      { headers: defaultHeaders }
    ).subscribe({
      next: (resp) => observer.next(resp.data),
      error: (error) => handleError(error, observer),
    });
  });
};

export const validateOtp = (data: ValidateOTPDataType) => {
  return new Observable((observer: Observer<ValidateOTPDataResponseType>) => {
    Axios.post<ValidateOTPDataResponseType>(
      `${BASE_URL}/organiser/validate-otp-for-auth`,
      data,
      { headers: defaultHeaders }
    ).subscribe({
      next: (resp) => observer.next(resp.data),
      error: (error) => handleError(error, observer),
    });
  });
};
