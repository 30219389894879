import * as auth from './auth';
import * as utils from './utils'
import * as overview from './overview'
import * as books from './books'
import * as orders from './orders'



export default {
    auth,
    orders,
    utils,
    books,
    overview,
};